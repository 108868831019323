/* eslint-disable no-unused-vars */
//import fontawesome from '@fortawesome/fontawesome';
//import light from '@fortawesome/fontawesome-pro-light'
//import {faPlusSquare, faMinusSquare} from '@fortawesome/fontawesome-pro-light'
//import solid from '@fortawesome/fontawesome-pro-solid'
//import regular from '@fortawesome/fontawesome-pro-regular'
//import brands from '@fortawesome/fontawesome-free-brands'
/* import faChevronRight from '@fortawesome/fontawesome-pro-regular/faChevronRight';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import faVimeo from '@fortawesome/fontawesome-free-brands/faVimeo';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';
import faLinkedin from '@fortawesome/fontawesome-free-brands/faLinkedin';
import faEnvelope from '@fortawesome/fontawesome-pro-solid/faEnvelope';
import faSearch from '@fortawesome/fontawesome-pro-light/faSearch';
import faTimes from '@fortawesome/fontawesome-pro-light/faTimes';
import faCog from '@fortawesome/fontawesome-pro-light/faCog';
import faClock from '@fortawesome/fontawesome-pro-light/faClock';
import faFileAlt from '@fortawesome/fontawesome-pro-light/faFileAlt';
import farFilePdf from '@fortawesome/fontawesome-pro-regular/faFilePdf'; */
import polyfills from './utils/polyfills.js';

const map = document.getElementById('tt-map');

if (map) {
  import('./tt-google-maps' /* webpackChunkName: "tt-google-maps" */ /* webpackPrefetch: true */).then(
    module => {
      module.default(map);
    }
  );
}


window.addEventListener('load', () => {

  polyfills();

  /* fontawesome.library.add(
    faChevronRight,
    faTwitter,
    faInstagram,
    faFacebook,
    faGoogle,
    faVimeo,
    faYoutube,
    faLinkedin,
    faEnvelope,
    faSearch,
    faTimes,
    faCog,
    faClock,
    faFileAlt,
    farFilePdf
  ); */

  // Query the DOM for dynamic imports
  const heroChevrons = Array.from(document.querySelectorAll('.hero-chevron'));
  const lightBoxImages = Array.from(document.querySelectorAll('[data-lightbox]'));

  import('./tt-navbar' /* webpackChunkName: "navbar" */ /* webpackPrefetch: true */).then(
    module => {
      module.default();
    }
  );


  if (lightBoxImages.length > 0) {
    import('./tt-lightbox' /* webpackChunkName: "lightbox" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(lightBoxImages);
      }
    );
  }

  if (heroChevrons.length > 0) {
    import('./tt-hero-chevron' /* webpackChunkName: "tt-hero-chevron" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(heroChevrons);
      }
    );
  }

  if (document.body.classList.contains('single')) {
    import('./tt-single-page' /* webpackChunkName: "single-page" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }
  
  if (document.body.classList.contains('single-qualifications')) {

    import('./tt-register-qualification' /* webpackChunkName: "register-qualifications" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if( document.body.classList.contains('page-template-page-truck-archive') || 
      document.body.classList.contains('page-template-page-rent-truck-archive') || 
      document.body.classList.contains('page-template-page-used-truck-archive') ||
      document.body.classList.contains('page-template-page-new-truck-archive')) {


    import('./tt-filter-trucks' /* webpackChunkName: "filter-trucks" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if( document.body.classList.contains('page-template-page-lift-archive') || 
      document.body.classList.contains('tax-lifts_category')) {

    import('./tt-filter-lifts' /* webpackChunkName: "filter-lifts" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if( document.body.classList.contains('page-template-page-cleaning-machine-archive') ||
      document.body.classList.contains('page-template-page-used-cleaning-machine-archive') ||
      document.body.classList.contains('tax-machines_category')) {

    import('./tt-filter-cleaning-machines' /* webpackChunkName: "filter-cleaning-machines" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if( document.getElementsByClassName('tt-news').length ||
      document.getElementsByClassName('tt-news-archive').length) {

    import('./tt-news' /* webpackChunkName: "filter-cleaning-machines" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

});
