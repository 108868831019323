function Polyfills() {

    if('objectFit' in document.documentElement.style !== false) {
        import('object-fit-images' /* webpackChunkName: "object-fit-images" */ /* webpackPrefetch: true */).then(
            module => {
                module.default();
            }
        );
    }
    
}



export default Polyfills;